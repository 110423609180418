img.selfie  {
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 2px;
  margin: 5px;
  width: 150px;
}

img.selfie:hover {
    box-shadow: 0 0 2px 2px rgba(110, 110, 110, 0.5);
    box-shadow: inset 0px 0px 50px 60px rgba(0,0,0,0.8);
  }


input.selfie {
    display: none;
    opacity: 0.5;
}

input.textBox {
    margin: 5px;
}

label {
    margin: 5px;
}



.imageSelector {
    display: flex;
    justify-content: space-between;
    justify-content: space-around;
}

.mainPage {
    max-width: 500px;
    margin: auto;
    background: white;
    padding: 15px;
  }

  .loadingPage {
    max-width: 500px;
    margin: auto;
    background: white;
    padding: 15;
    height: 500px;
    display: flex;
    justify-content: center;
    
  }

  
  .loaderMessage {
    position: absolute;
    top: 10%;
  }

  .loader {
    position: absolute;
    top: 15%;
  }


body {
    background: #555;
  }





  button {
    border: none;
    color: rgb(187, 255, 176);
    margin-top: 50px;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: rgb(168, 255, 151); 
    color: black; 
    border: 2px solid #4CAF50;
  }
    
  button:hover {
    background-color: #4CAF50;
    color: white;
  }

  img.imageGuide {
    border: 1px solid rgb(0, 0, 0);
    border-radius: 2px;
    margin: 5px;
    width: 50%;
  }

  /* .userInput{
    padding-top:5px;

  } */
